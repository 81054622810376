.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.card-icon {
    width: 60px;
    height: 60px;
    position: relative;
    top: -15px;
    left: 20px;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
    background: -webkit-linear-gradient(30deg,#ffa726,#fb8c00);
    background: linear-gradient(60deg,#ffa726,#fb8c00);
}

.innerCenter {
    display: table !important;
    margin: 0 auto;
    line-height: 56px !important;
    color: white;
    font-size: 2em !important;
}


.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.float-right {
    float: right !important;
}
.headlineDashboard {
    margin: 10px;
    margin-top: -30px;
    color: rgba(0, 0, 0, 0.54);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
